import React from 'react';
import { ChatMessage } from '../../types/chat';
import Message from './Message';
import TypingIndicator from './TypingIndicator';

interface ChatMessagesProps {
  messages: ChatMessage[];
  isTyping: boolean;
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({ 
  messages, 
  isTyping, 
  messagesEndRef 
}) => {
  return (
    <div className="flex-1 overflow-y-auto px-4 py-2 space-y-4 custom-scrollbar">
      <div className="max-w-3xl mx-auto space-y-4">
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        {isTyping && <TypingIndicator />}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatMessages;