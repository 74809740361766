import React from 'react';
import { Download, Share } from 'lucide-react';
import { exportChatToPDF } from '../../utils/export';
import { ChatMessage } from '../../types/chat';

interface ChatActionsProps {
  messages: ChatMessage[];
  userName: string;
  shareUrl: string;
}

const ChatActions: React.FC<ChatActionsProps> = ({ messages, userName, shareUrl }) => {
  const handleExport = () => {
    exportChatToPDF(messages, userName);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Chat with ${userName}'s CV`,
          text: 'Check out this CV chat!',
          url: shareUrl
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      await navigator.clipboard.writeText(shareUrl);
      alert('Link copied to clipboard!');
    }
  };

  return (
    <div className="fixed bottom-24 right-4 flex flex-col gap-2">
      <button
        onClick={handleExport}
        className="p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors shadow-lg"
        title="Export chat"
      >
        <Download size={20} />
      </button>
      <button
        onClick={handleShare}
        className="p-3 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors shadow-lg"
        title="Share chat"
      >
        <Share size={20} />
      </button>
    </div>
  );
};

export default ChatActions;