import { ChatMessage } from '../types/chat';
import { sendChatRequest } from './api/chat';
import { ApiRequestError } from './api/errors';

export const generateChatResponse = async (
  messages: ChatMessage[], 
  cvInfo: string,
  cvFileUrl: string,
  userInfo: string,
  userId?: string,
  cvId?: string
): Promise<string> => {
  try {
    return await sendChatRequest({
      messages,
      cvInfo,
      cvFileUrl,
      userInfo,
      userId,
      cvId
    });
  } catch (error) {
    if (error instanceof ApiRequestError) {
      return error.message;
    }
    return "I'm sorry, but I encountered an error while processing your request. Please try again later.";
  }
};