import React, { useState } from 'react';
import { Send, UserPlus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/layout/Header';
import LoginModal from '../components/auth/LoginModal';
import { generateChatResponse } from '../services/openai';
import { useSupabase } from '../contexts/SupabaseContext';

const Home: React.FC = () => {
  const [messages, setMessages] = useState<{ role: string; content: string }[]>([
    { role: 'assistant', content: "Hello! I'm the ChatMyCV AI. How can I assist you today?" },
  ]);
  const [input, setInput] = useState('');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const { user } = useSupabase();
  const navigate = useNavigate();

  const handleSendMessage = async () => {
    if (input.trim()) {
      const newMessage = { role: 'user', content: input };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInput('');
      setIsTyping(true);

      try {
        const response = await generateChatResponse([...messages, newMessage], "This is a general AI assistant for ChatMyCV. It doesn't have specific CV information.");
        const botResponse = { role: 'assistant', content: response || "I'm sorry, I couldn't generate a response." };
        setMessages(prevMessages => [...prevMessages, botResponse]);
      } catch (error) {
        console.error('Error getting AI response:', error);
        setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' }]);
      } finally {
        setIsTyping(false);
      }
    }
  };

  const handleUpdateCV = () => {
    if (user) {
      navigate('/profile');
    } else {
      setIsLoginModalOpen(true);
    }
  };

  const handleLoginSuccess = () => {
    setIsLoginModalOpen(false);
    navigate('/profile');
  };

  const suggestions = [
    "What is ChatMyCV?",
    "How can I create my own CV chat?",
    "What are the benefits of using ChatMyCV?",
    user ? "Update your CV" : "Sign up & Create your ChatCV"
  ];

  return (
    <div className="flex flex-col h-screen bg-gray-900">
      <Header />
      
      <div className="flex-grow overflow-auto p-4 space-y-4 custom-scrollbar">
        <div className="max-w-3xl mx-auto space-y-4">
          {messages.map((message, index) => (
            <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
              <div className={`chat-bubble ${message.role === 'user' ? 'chat-bubble-user' : 'chat-bubble-assistant'}`}>
                {message.content}
              </div>
            </div>
          ))}
          {isTyping && (
            <div className="flex justify-start">
              <div className="chat-bubble chat-bubble-assistant">
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <div className="p-4 border-t border-gray-700">
        <div className="max-w-3xl mx-auto">
          <div className="flex flex-wrap justify-center gap-2 mb-4">
            {suggestions.map((suggestion, index) => (
              <button
                key={index}
                onClick={() => index === suggestions.length - 1 ? handleUpdateCV() : setInput(suggestion)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                  index === suggestions.length - 1
                    ? 'bg-green-600 hover:bg-green-700 text-white'
                    : 'bg-gray-700 hover:bg-gray-600 text-gray-200 hover:text-white'
                }`}
              >
                {index === suggestions.length - 1 && <UserPlus size={16} className="inline-block mr-2" />}
                {suggestion}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-[1fr,auto] focus-within:ring-2 focus-within:ring-blue-500 rounded-full overflow-hidden">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder="Ask about ChatMyCV..."
              className="px-4 py-3 bg-gray-800 text-gray-200 focus:outline-none"
            />
            <button
              onClick={handleSendMessage}
              className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 transition-colors duration-200"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />
    </div>
  );
};

export default Home;