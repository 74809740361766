import React from 'react';
import { User, Calendar } from 'lucide-react';

interface PersonalInfoFormProps {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  personalInfo: string;
  onFirstNameChange: (value: string) => void;
  onLastNameChange: (value: string) => void;
  onDateOfBirthChange: (value: string) => void;
  onPersonalInfoChange: (value: string) => void;
}

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({
  firstName,
  lastName,
  dateOfBirth,
  personalInfo,
  onFirstNameChange,
  onLastNameChange,
  onDateOfBirthChange,
  onPersonalInfoChange,
}) => {
  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="firstName" className="block text-sm font-medium text-gray-300 mb-1">First Name</label>
        <div className="relative">
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => onFirstNameChange(e.target.value)}
            className="w-full pl-10 pr-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter first name"
          />
          <User size={18} className="absolute left-3 top-2.5 text-gray-400" />
        </div>
      </div>
      <div>
        <label htmlFor="lastName" className="block text-sm font-medium text-gray-300 mb-1">Last Name</label>
        <div className="relative">
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => onLastNameChange(e.target.value)}
            className="w-full pl-10 pr-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter last name"
          />
          <User size={18} className="absolute left-3 top-2.5 text-gray-400" />
        </div>
      </div>
      <div>
        <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-300 mb-1">Date of Birth</label>
        <div className="relative">
          <input
            type="date"
            id="dateOfBirth"
            value={dateOfBirth}
            onChange={(e) => onDateOfBirthChange(e.target.value)}
            className="w-full pl-10 pr-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Calendar size={18} className="absolute left-3 top-2.5 text-gray-400" />
        </div>
      </div>
      <div>
        <label htmlFor="personal-info" className="block text-sm font-medium text-gray-300 mb-2">
          Additional Personal Information
        </label>
        <textarea
          id="personal-info"
          rows={4}
          className="w-full px-3 py-2 text-gray-300 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter any additional personal information..."
          value={personalInfo}
          onChange={(e) => onPersonalInfoChange(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
};

export default PersonalInfoForm;