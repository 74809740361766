import { API_CONFIG } from './config';
import { makeRequest } from './request';
import { ChatRequestPayload, ChatResponse } from './types';

export async function sendChatRequest(payload: ChatRequestPayload): Promise<string> {
  try {
    const response = await makeRequest<ChatResponse>(API_CONFIG.ENDPOINTS.CHAT, payload);
    return response.message;
  } catch (error) {
    console.error('Chat request failed:', error);
    throw error;
  }
}