import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Upload as UploadIcon } from 'lucide-react';
import { useSupabase } from '../contexts/SupabaseContext';
import Header from '../components/layout/Header';
import FileUpload from '../components/cv/FileUpload';
import PersonalInfoForm from '../components/cv/PersonalInfoForm';
import ImageUpload from '../components/cv/ImageUpload';
import { useFormValidation } from '../hooks/useFormValidation';
import { personalInfoSchema, fileSchema, imageSchema } from '../utils/validation';
import FormError from '../components/common/FormError';

const Upload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [personalInfo, setPersonalInfo] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { supabase, user } = useSupabase();
  const { errors, validate } = useFormValidation(personalInfoSchema);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file || !user) {
      setError('Please select a CV file to upload');
      return;
    }

    const formData = { firstName, lastName, dateOfBirth, personalInfo };
    if (!validate(formData)) {
      return;
    }

    if (!fileSchema.safeParse({ file }).success) {
      setError('Invalid CV file. Please use a PDF under 10MB.');
      return;
    }

    if (imageFile && !imageSchema.safeParse({ file: imageFile }).success) {
      setError('Invalid image file. Please use an image under 2MB.');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      // Upload CV file
      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}-${Math.random().toString(36).substring(2)}.${fileExt}`;
      const { error: uploadError } = await supabase.storage
        .from('cvs')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Upload profile image if provided
      let imageFileName = null;
      if (imageFile) {
        const imageExt = imageFile.name.split('.').pop();
        imageFileName = `${user.id}-${Math.random().toString(36).substring(2)}.${imageExt}`;
        const { error: imageUploadError } = await supabase.storage
          .from('profile-images')
          .upload(imageFileName, imageFile);

        if (imageUploadError) throw imageUploadError;
      }

      // Store CV information in the database
      const { data, error: insertError } = await supabase
        .from('user_cvs')
        .insert([
          { 
            user_id: user.id, 
            cv_file: fileName, 
            personal_info: personalInfo,
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth,
            profile_image: imageFileName
          }
        ])
        .select();

      if (insertError) throw insertError;

      if (data && data[0]) {
        navigate(`/chat/${data[0].id}`);
      } else {
        throw new Error('Failed to create chat session');
      }
    } catch (error) {
      console.error('Error uploading CV:', error);
      setError(error instanceof Error ? error.message : 'Failed to upload CV. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-2xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8">
          <h1 className="text-3xl font-bold text-blue-400 mb-8">Upload Your CV</h1>
          
          {error && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-400">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <ImageUpload
              currentImageUrl={null}
              onImageChange={setImageFile}
            />

            <FileUpload 
              onFileChange={(e) => setFile(e.target.files?.[0] || null)}
              currentFile={file}
            />

            <PersonalInfoForm
              firstName={firstName}
              lastName={lastName}
              dateOfBirth={dateOfBirth}
              personalInfo={personalInfo}
              onFirstNameChange={setFirstName}
              onLastNameChange={setLastName}
              onDateOfBirthChange={setDateOfBirth}
              onPersonalInfoChange={setPersonalInfo}
            />

            {Object.entries(errors).map(([key, error]) => (
              <FormError key={key} error={error} />
            ))}

            <button
              type="submit"
              disabled={isUploading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
            >
              {isUploading ? 'Uploading...' : 'Upload CV'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Upload;