export const API_CONFIG = {
  BASE_URL: '/.netlify/functions',
  ENDPOINTS: {
    CHAT: '/chat'
  },
  TIMEOUT: 30000, // 30 seconds
  RETRY_ATTEMPTS: 3,
  RETRY_DELAY: 1000, // 1 second
};

export const ERROR_MESSAGES = {
  RATE_LIMIT: 'Rate limit exceeded. Please wait before sending more messages.',
  NETWORK: 'Network error. Please check your connection and try again.',
  TIMEOUT: 'Request timed out. Please try again.',
  SERVER: 'Server error. Please try again later.',
  UNAUTHORIZED: 'Unauthorized. Please log in again.',
};