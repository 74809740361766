import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';
import { useSupabase } from '../contexts/SupabaseContext';
import { generateChatResponse } from '../services/openai';
import { getImageUrl } from '../utils/supabase';
import Header from '../components/layout/Header';
import UserInfoCard from '../components/chat/UserInfoCard';
import ChatMessages from '../components/chat/ChatMessages';
import ChatInput from '../components/chat/ChatInput';
import ChatActions from '../components/chat/ChatActions';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { ChatMessage } from '../types/chat';

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cvInfo, setCvInfo] = useState('');
  const [cvFileUrl, setCvFileUrl] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [pdfProcessingError, setPdfProcessingError] = useState<string | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [isOwnCV, setIsOwnCV] = useState(false);
  const { id } = useParams<{ id: string }>();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { supabase, user } = useSupabase();
  const navigate = useNavigate();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    const fetchCVInfo = async () => {
      if (!id) {
        setError('No CV ID provided');
        setIsLoading(false);
        return;
      }

      try {
        const { data, error: fetchError } = await supabase
          .from('user_cvs')
          .select('personal_info, cv_file, first_name, last_name, date_of_birth, user_id, profile_image')
          .eq('id', id)
          .single();

        if (fetchError) {
          throw fetchError;
        }

        if (!data) {
          throw new Error('CV not found');
        }

        setCvInfo(data.personal_info || '');
        setFirstName(data.first_name || '');
        setLastName(data.last_name || '');
        setDateOfBirth(data.date_of_birth || '');
        setIsOwnCV(user?.id === data.user_id);
        
        if (data.profile_image) {
          const imageUrl = getImageUrl(supabase, data.profile_image);
          setProfileImageUrl(imageUrl);
        }
        
        if (data.cv_file) {
          const { data: fileUrl } = supabase.storage
            .from('cvs')
            .getPublicUrl(data.cv_file);
          
          if (fileUrl) {
            setCvFileUrl(fileUrl.publicUrl);
          } else {
            setPdfProcessingError('Failed to access the CV file. The chat will continue with limited information.');
          }
        } else {
          setPdfProcessingError('No CV file found. The chat will continue with limited information.');
        }

        setMessages([{ 
          role: 'assistant', 
          content: `Welcome! I'm the AI representation of ${data.first_name} ${data.last_name}'s CV. How can I assist you today?`,
          timestamp: Date.now()
        }]);

      } catch (err) {
        console.error('Error fetching CV info:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch CV information');
        if (err instanceof Error && err.message === 'CV not found') {
          navigate('/');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchCVInfo();
  }, [id, supabase, navigate, user]);

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const newMessage = { role: 'user', content: input, timestamp: Date.now() };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    setIsTyping(true);

    try {
      const userInfo = `First Name: ${firstName}, Last Name: ${lastName}, Date of Birth: ${dateOfBirth}`;
      const response = await generateChatResponse([...messages, newMessage], cvInfo, cvFileUrl, userInfo);
      const botResponse = { 
        role: 'assistant', 
        content: response,
        timestamp: Date.now()
      };
      setMessages(prevMessages => [...prevMessages, botResponse]);
    } catch (error) {
      console.error('Error getting AI response:', error);
      setMessages(prevMessages => [...prevMessages, { 
        role: 'assistant', 
        content: 'Sorry, I encountered an error. Please try again.',
        timestamp: Date.now()
      }]);
    } finally {
      setIsTyping(false);
    }
  };

  const suggestions = [
    "What are your key skills?",
    "Tell me about your work experience",
    "What's your educational background?",
    "What are your career goals?",
    "Do you have any certifications?"
  ];

  if (isLoading) {
    return (
      <div className="flex flex-col h-screen bg-gray-900">
        <Header />
        <div className="flex-1 flex items-center justify-center">
          <LoadingSpinner size="lg" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col h-screen bg-gray-900">
        <Header />
        <div className="flex-1 flex items-center justify-center">
          <div className="bg-red-500/10 border border-red-500 rounded-lg p-6 max-w-md mx-4 text-center">
            <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
            <h2 className="text-xl font-semibold text-red-400 mb-2">Error Loading CV</h2>
            <p className="text-gray-300">{error}</p>
            <button
              onClick={() => navigate('/')}
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
            >
              Return Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-gray-900">
      <Header userName={`${firstName} ${lastName}`} hideUserControls={!isOwnCV} />
      <div className="flex-1 overflow-hidden flex flex-col">
        <UserInfoCard
          firstName={firstName}
          lastName={lastName}
          dateOfBirth={dateOfBirth}
          profileImageUrl={profileImageUrl}
        />
        
        {pdfProcessingError && (
          <div className="px-4 py-2">
            <div className="bg-yellow-500 text-black p-3 rounded mb-4 flex items-center">
              <AlertTriangle className="mr-2" />
              <span>{pdfProcessingError}</span>
            </div>
          </div>
        )}

        <div className="flex-1 flex flex-col">
          <ChatMessages
            messages={messages}
            isTyping={isTyping}
            messagesEndRef={messagesEndRef}
          />

          <ChatInput
            input={input}
            setInput={setInput}
            handleSendMessage={handleSendMessage}
            suggestions={suggestions}
            hideUpdateButton={!isOwnCV}
          />
        </div>

        <ChatActions
          messages={messages}
          userName={`${firstName} ${lastName}`}
          shareUrl={window.location.href}
        />
      </div>
    </div>
  );
};

export default Chat;