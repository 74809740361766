import React from 'react';
import { Link as LinkIcon } from 'lucide-react';

interface CVUrlDisplayProps {
  cvUrl: string | null;
}

const CVUrlDisplay: React.FC<CVUrlDisplayProps> = ({ cvUrl }) => {
  if (!cvUrl) return null;

  const fullUrl = window.location.origin + cvUrl;

  return (
    <div className="mb-4 p-4 bg-gray-700 rounded-lg">
      <h3 className="text-lg font-semibold text-blue-300 mb-2">Your CV Chat URL</h3>
      <div className="flex items-center bg-gray-600 rounded-md">
        <input
          type="text"
          value={fullUrl}
          readOnly
          className="flex-grow bg-transparent text-gray-200 px-3 py-2 focus:outline-none"
        />
        <button
          onClick={() => navigator.clipboard.writeText(fullUrl)}
          className="bg-blue-500 text-white px-3 py-2 rounded-r-md hover:bg-blue-600 transition-colors duration-200"
        >
          <LinkIcon size={18} />
        </button>
      </div>
    </div>
  );
};

export default CVUrlDisplay;