import { SupabaseClient } from '@supabase/supabase-js';

export const getImageUrl = (supabase: SupabaseClient, path: string | null): string | null => {
  if (!path) return null;
  
  // Remove any duplicate base URLs if present
  const cleanPath = path.replace(/^.*storage\/v1\/object\/public\/[^/]+\//, '');
  
  const { data } = supabase.storage
    .from('profile-images')
    .getPublicUrl(cleanPath);
    
  return data.publicUrl;
};

export const getCVUrl = (supabase: SupabaseClient, path: string | null): string | null => {
  if (!path) return null;
  
  // Remove any duplicate base URLs if present
  const cleanPath = path.replace(/^.*storage\/v1\/object\/public\/[^/]+\//, '');
  
  const { data } = supabase.storage
    .from('cvs')
    .getPublicUrl(cleanPath);
    
  return data.publicUrl;
};