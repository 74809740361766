import { ERROR_MESSAGES } from './config';
import { ApiError } from './types';

export class ApiRequestError extends Error implements ApiError {
  status?: number;
  code?: string;

  constructor(message: string, status?: number, code?: string) {
    super(message);
    this.name = 'ApiRequestError';
    this.status = status;
    this.code = code;
  }
}

export const handleApiError = (error: unknown): ApiError => {
  if (error instanceof ApiRequestError) {
    return error;
  }

  if (error instanceof Response) {
    switch (error.status) {
      case 429:
        return new ApiRequestError(ERROR_MESSAGES.RATE_LIMIT, 429, 'RATE_LIMIT');
      case 401:
        return new ApiRequestError(ERROR_MESSAGES.UNAUTHORIZED, 401, 'UNAUTHORIZED');
      case 502:
        return new ApiRequestError(ERROR_MESSAGES.SERVER, 502, 'BAD_GATEWAY');
      default:
        return new ApiRequestError(ERROR_MESSAGES.SERVER, error.status);
    }
  }

  if (error instanceof Error) {
    if (error.name === 'AbortError') {
      return new ApiRequestError(ERROR_MESSAGES.TIMEOUT, 408, 'TIMEOUT');
    }
    if (error.message.includes('NetworkError')) {
      return new ApiRequestError(ERROR_MESSAGES.NETWORK, 0, 'NETWORK_ERROR');
    }
    return new ApiRequestError(error.message);
  }

  return new ApiRequestError(ERROR_MESSAGES.SERVER);
};