import React from 'react';
import { ChatMessage } from '../../types/chat';
import { formatTimestamp } from '../../utils/date';

interface MessageProps {
  message: ChatMessage;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const isUser = message.role === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} group`}>
      <div
        className={`
          chat-bubble
          ${isUser ? 'chat-bubble-user' : 'chat-bubble-assistant'}
          transition-all duration-200
          hover:shadow-lg
          relative
        `}
      >
        {message.content}
        {message.timestamp && (
          <span className="absolute bottom-0 right-0 transform translate-y-full text-xs text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            {formatTimestamp(message.timestamp)}
          </span>
        )}
      </div>
    </div>
  );
};

export default Message;