import { API_CONFIG } from './config';
import { ApiRequestError, handleApiError } from './errors';
import { ChatRequestPayload, ChatResponse } from './types';

const createAbortController = (timeout: number) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeout);
  return controller;
};

async function fetchWithRetry(
  url: string,
  options: RequestInit,
  retries: number = API_CONFIG.RETRY_ATTEMPTS
): Promise<Response> {
  try {
    const response = await fetch(url, options);
    
    if (!response.ok) {
      throw response;
    }
    
    return response;
  } catch (error) {
    if (retries === 0) throw error;
    
    await new Promise(resolve => setTimeout(resolve, API_CONFIG.RETRY_DELAY));
    return fetchWithRetry(url, options, retries - 1);
  }
}

export async function makeRequest<T>(
  endpoint: string,
  payload: ChatRequestPayload
): Promise<T> {
  const controller = createAbortController(API_CONFIG.TIMEOUT);

  try {
    const response = await fetchWithRetry(
      `${API_CONFIG.BASE_URL}${endpoint}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        signal: controller.signal,
      }
    );

    const data = await response.json();
    return data as T;
  } catch (error) {
    throw handleApiError(error);
  }
}