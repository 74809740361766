import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../contexts/SupabaseContext';
import Header from '../components/layout/Header';
import FileUpload from '../components/cv/FileUpload';
import ImageUpload from '../components/cv/ImageUpload';
import PersonalInfoForm from '../components/cv/PersonalInfoForm';
import CVUrlDisplay from '../components/cv/CVUrlDisplay';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { useFormValidation } from '../hooks/useFormValidation';
import { personalInfoSchema, fileSchema, imageSchema } from '../utils/validation';
import FormError from '../components/common/FormError';
import { getImageUrl } from '../utils/supabase';

const Profile = () => {
  const [file, setFile] = useState<File | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [personalInfo, setPersonalInfo] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cvUrl, setCvUrl] = useState<string | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [existingCvFile, setExistingCvFile] = useState<string | null>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const { supabase, user } = useSupabase();
  const navigate = useNavigate();
  const { errors, validate } = useFormValidation(personalInfoSchema);

  useEffect(() => {
    const fetchExistingCV = async () => {
      if (!user) {
        navigate('/');
        return;
      }

      try {
        const { data, error } = await supabase
          .from('user_cvs')
          .select('personal_info, id, first_name, last_name, date_of_birth, cv_file, profile_image')
          .eq('user_id', user.id)
          .single();

        if (data) {
          setPersonalInfo(data.personal_info || '');
          setCvUrl(`/chat/${data.id}`);
          setFirstName(data.first_name || '');
          setLastName(data.last_name || '');
          setDateOfBirth(data.date_of_birth || '');
          setExistingCvFile(data.cv_file || null);
          
          if (data.profile_image) {
            const imageUrl = getImageUrl(supabase, 'profile-images', data.profile_image);
            setProfileImageUrl(imageUrl);
          }
        } else if (error && error.code !== 'PGRST116') {
          setError('Failed to fetch profile information. Please try again.');
        }
      } catch (err) {
        setError('An unexpected error occurred. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchExistingCV();
  }, [user, supabase, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      navigate('/');
      return;
    }

    const formData = { firstName, lastName, dateOfBirth, personalInfo };
    if (!validate(formData)) {
      return;
    }

    if (imageFile && !imageSchema.safeParse({ file: imageFile }).success) {
      setError('Invalid image file. Please use an image under 2MB.');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      let fileName = existingCvFile;
      if (file) {
        if (!fileSchema.parse({ file }).file) {
          return;
        }

        const fileExt = file.name.split('.').pop();
        fileName = `${user.id}/${Math.random().toString(36).substring(2)}.${fileExt}`;
        const { error: uploadError } = await supabase.storage
          .from('cvs')
          .upload(fileName, file);

        if (uploadError) throw uploadError;
      }

      let imageFileName = null;
      if (imageFile) {
        const imageExt = imageFile.name.split('.').pop();
        imageFileName = `${user.id}/${Math.random().toString(36).substring(2)}.${imageExt}`;
        const { error: imageUploadError } = await supabase.storage
          .from('profile-images')
          .upload(imageFileName, imageFile);

        if (imageUploadError) throw imageUploadError;
      }

      const { data: existingData, error: checkError } = await supabase
        .from('user_cvs')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }

      const cvData = {
        cv_file: fileName,
        personal_info: personalInfo,
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
        ...(imageFileName && { profile_image: imageFileName })
      };

      let upsertError;
      let newData;
      if (existingData) {
        const { data, error } = await supabase
          .from('user_cvs')
          .update(cvData)
          .eq('user_id', user.id)
          .select();
        upsertError = error;
        newData = data;
      } else {
        const { data, error } = await supabase
          .from('user_cvs')
          .insert({ ...cvData, user_id: user.id })
          .select();
        upsertError = error;
        newData = data;
      }

      if (upsertError) throw upsertError;

      if (newData && newData[0]) {
        navigate(`/chat/${newData[0].id}`);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error instanceof Error ? error.message : 'Failed to update profile');
    } finally {
      setIsUploading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Header />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <LoadingSpinner size="lg" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-2xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8">
          <h1 className="text-3xl font-bold text-blue-400 mb-8">Update Your Profile</h1>
          
          {error && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-400">
              {error}
            </div>
          )}

          <CVUrlDisplay cvUrl={cvUrl} />
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <ImageUpload
              currentImageUrl={profileImageUrl}
              onImageChange={setImageFile}
            />
            
            <FileUpload 
              onFileChange={(e) => setFile(e.target.files?.[0] || null)} 
              currentFile={file} 
            />
            
            <PersonalInfoForm
              firstName={firstName}
              lastName={lastName}
              dateOfBirth={dateOfBirth}
              personalInfo={personalInfo}
              onFirstNameChange={setFirstName}
              onLastNameChange={setLastName}
              onDateOfBirthChange={setDateOfBirth}
              onPersonalInfoChange={setPersonalInfo}
            />

            {Object.entries(errors).map(([key, error]) => (
              <FormError key={key} error={error} />
            ))}

            <button
              type="submit"
              disabled={isUploading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
            >
              {isUploading ? (
                <div className="flex items-center justify-center">
                  <LoadingSpinner size="sm" />
                  <span className="ml-2">Updating...</span>
                </div>
              ) : (
                'Update Profile'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;