import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useSupabase } from '../../contexts/SupabaseContext';
import { useNavigate } from 'react-router-dom';
import FileUpload from './FileUpload';
import PersonalInfoForm from './PersonalInfoForm';
import CVUrlDisplay from './CVUrlDisplay';

interface UpdateCVModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UpdateCVModal: React.FC<UpdateCVModalProps> = ({ isOpen, onClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [personalInfo, setPersonalInfo] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cvUrl, setCvUrl] = useState<string | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [existingCvFile, setExistingCvFile] = useState<string | null>(null);
  const { supabase, user } = useSupabase();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExistingCV = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('user_cvs')
          .select('personal_info, id, first_name, last_name, date_of_birth, cv_file')
          .eq('user_id', user.id)
          .single();

        if (data) {
          setPersonalInfo(data.personal_info || '');
          setCvUrl(`/chat/${data.id}`);
          setFirstName(data.first_name || '');
          setLastName(data.last_name || '');
          setDateOfBirth(data.date_of_birth || '');
          setExistingCvFile(data.cv_file || null);
        } else if (error && error.code !== 'PGRST116') {
          console.error('Error fetching existing CV:', error);
          setError('Failed to fetch existing CV information. Please try again.');
        }
      }
    };

    if (isOpen) {
      fetchExistingCV();
    }
  }, [user, supabase, isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to update your CV.');
      return;
    }

    setIsUploading(true);
    setError(null);
    try {
      let fileName = existingCvFile;
      if (file) {
        const fileExt = file.name.split('.').pop();
        fileName = `${user.id}-${Math.random().toString(36).substring(2)}.${fileExt}`;
        const { error: uploadError } = await supabase.storage
          .from('cvs')
          .upload(fileName, file);

        if (uploadError) throw uploadError;
      }

      const { data: existingData, error: checkError } = await supabase
        .from('user_cvs')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }

      let upsertError;
      let newData;
      if (existingData) {
        const { data, error } = await supabase
          .from('user_cvs')
          .update({ 
            cv_file: fileName,
            personal_info: personalInfo,
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth
          })
          .eq('user_id', user.id)
          .select();
        upsertError = error;
        newData = data;
      } else {
        const { data, error } = await supabase
          .from('user_cvs')
          .insert({ 
            user_id: user.id,
            cv_file: fileName,
            personal_info: personalInfo,
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth
          })
          .select();
        upsertError = error;
        newData = data;
      }

      if (upsertError) throw upsertError;

      if (newData && newData[0]) {
        const chatUrl = `/chat/${newData[0].id}`;
        onClose();
        navigate(chatUrl);
      }
    } catch (error) {
      console.error('Error updating CV:', error);
      if (error instanceof Error) {
        setError(`Failed to update CV: ${error.message}`);
      } else {
        setError('Failed to update CV. Please try again.');
      }
    } finally {
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto">
      <div className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full m-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-blue-400">Update Your CV</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>
        <div className="max-h-[calc(100vh-200px)] overflow-y-auto pr-4">
          {error && (
            <div className="mb-4 p-3 bg-red-500 text-white rounded-md">
              {error}
            </div>
          )}
          <CVUrlDisplay cvUrl={cvUrl} />
          <form onSubmit={handleSubmit} className="space-y-4">
            <FileUpload onFileChange={handleFileChange} currentFile={file} />
            <PersonalInfoForm
              firstName={firstName}
              lastName={lastName}
              dateOfBirth={dateOfBirth}
              personalInfo={personalInfo}
              onFirstNameChange={setFirstName}
              onLastNameChange={setLastName}
              onDateOfBirthChange={setDateOfBirth}
              onPersonalInfoChange={setPersonalInfo}
            />
            <button
              type="submit"
              disabled={isUploading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              {isUploading ? 'Updating...' : 'Update CV'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateCVModal;