import React from 'react';
import { User, Calendar } from 'lucide-react';
import { useSupabase } from '../../contexts/SupabaseContext';
import { getImageUrl } from '../../utils/supabase';

interface UserInfoCardProps {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  profileImageUrl?: string | null;
}

const UserInfoCard: React.FC<UserInfoCardProps> = ({ 
  firstName, 
  lastName, 
  dateOfBirth,
  profileImageUrl 
}) => {
  const { supabase } = useSupabase();
  const formattedDate = dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : '';
  const imageUrl = profileImageUrl ? getImageUrl(supabase, profileImageUrl) : null;

  return (
    <div className="sticky top-0 z-10 bg-gray-900 px-4 py-2">
      <div className="bg-gray-800 p-4 rounded-lg shadow-md">
        <div className="flex items-center space-x-4 mb-4">
          {imageUrl ? (
            <img
              src={imageUrl}
              alt={`${firstName} ${lastName}`}
              className="w-16 h-16 rounded-full object-cover border-2 border-blue-500"
            />
          ) : (
            <div className="w-16 h-16 rounded-full bg-gray-700 flex items-center justify-center border-2 border-gray-600">
              <User size={32} className="text-gray-400" />
            </div>
          )}
          <div>
            <h2 className="text-xl font-semibold text-gray-200">{firstName} {lastName}</h2>
            {dateOfBirth && (
              <p className="text-gray-400 text-sm">
                <Calendar size={14} className="inline mr-1" />
                {formattedDate}
              </p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-400 mb-1">First Name</label>
            <div className="relative">
              <input
                type="text"
                id="firstName"
                value={firstName}
                className="w-full pl-10 pr-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter first name"
                readOnly
              />
              <User size={18} className="absolute left-3 top-2.5 text-gray-400" />
            </div>
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-400 mb-1">Last Name</label>
            <div className="relative">
              <input
                type="text"
                id="lastName"
                value={lastName}
                className="w-full pl-10 pr-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter last name"
                readOnly
              />
              <User size={18} className="absolute left-3 top-2.5 text-gray-400" />
            </div>
          </div>
          <div>
            <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-400 mb-1">Date of Birth</label>
            <div className="relative">
              <input
                type="date"
                id="dateOfBirth"
                value={dateOfBirth}
                className="w-full pl-10 pr-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                readOnly
              />
              <Calendar size={18} className="absolute left-3 top-2.5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoCard;