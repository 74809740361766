import { jsPDF } from 'jspdf';
import { format } from 'date-fns';
import { ChatMessage } from '../types/chat';

export function exportChatToPDF(messages: ChatMessage[], userName: string) {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 20;
  const lineHeight = 7;
  let yPosition = margin;

  // Add title
  doc.setFontSize(16);
  doc.text(`Chat Conversation with ${userName}'s CV`, margin, yPosition);
  yPosition += lineHeight * 2;

  // Add timestamp
  doc.setFontSize(10);
  doc.text(`Generated on: ${format(new Date(), 'PPpp')}`, margin, yPosition);
  yPosition += lineHeight * 2;

  // Add messages
  doc.setFontSize(12);
  messages.forEach((message) => {
    const role = message.role === 'user' ? 'You' : 'CV Assistant';
    const text = `${role}: ${message.content}`;
    
    const splitText = doc.splitTextToSize(text, pageWidth - margin * 2);
    
    // Check if we need a new page
    if (yPosition + splitText.length * lineHeight > doc.internal.pageSize.getHeight() - margin) {
      doc.addPage();
      yPosition = margin;
    }
    
    doc.text(splitText, margin, yPosition);
    yPosition += splitText.length * lineHeight + 5;
  });

  // Save the PDF
  doc.save(`chat-with-${userName.toLowerCase()}-cv.pdf`);
}