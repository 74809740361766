import React, { useState } from 'react';
import { Upload as UploadIcon, X } from 'lucide-react';

interface ImageUploadProps {
  currentImageUrl: string | null;
  onImageChange: (file: File | null) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ currentImageUrl, onImageChange }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(currentImageUrl);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onImageChange(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setPreviewUrl(null);
    onImageChange(null);
  };

  return (
    <div className="space-y-4">
      <label className="block text-sm font-medium text-gray-300">Profile Image</label>
      {previewUrl ? (
        <div className="relative w-32 h-32">
          <img
            src={previewUrl}
            alt="Profile preview"
            className="w-full h-full object-cover rounded-full"
          />
          <button
            onClick={handleRemoveImage}
            className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
          >
            <X size={16} />
          </button>
        </div>
      ) : (
        <label className="flex flex-col items-center justify-center w-32 h-32 border-2 border-gray-600 border-dashed rounded-full cursor-pointer bg-gray-700 hover:bg-gray-600 transition-all duration-300">
          <div className="flex flex-col items-center justify-center">
            <UploadIcon className="w-8 h-8 text-gray-400" />
            <p className="text-xs text-gray-400 mt-2">Upload Image</p>
          </div>
          <input
            type="file"
            className="hidden"
            accept="image/*"
            onChange={handleFileChange}
          />
        </label>
      )}
      <p className="text-xs text-gray-400">
        Recommended: Square image, max 2MB
      </p>
    </div>
  );
};

export default ImageUpload;