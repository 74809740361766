import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/layout/Footer';
import { SupabaseProvider } from './contexts/SupabaseContext';
import Upload from './pages/Upload';
import Chat from './pages/Chat';
import Profile from './pages/Profile';
import ErrorBoundary from './components/common/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <SupabaseProvider>
        <Router>
          <div className="flex flex-col min-h-screen bg-gray-900">
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/upload" element={<Upload />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/chat/:id" element={<Chat />} />
                <Route path="*" element={<div className="text-white text-center mt-10">Page not found</div>} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </SupabaseProvider>
    </ErrorBoundary>
  );
}

export default App;