import React from 'react';

interface FormErrorProps {
  error?: string;
}

const FormError: React.FC<FormErrorProps> = ({ error }) => {
  if (!error) return null;

  return (
    <p className="text-red-400 text-sm mt-1">
      {error}
    </p>
  );
};

export default FormError;