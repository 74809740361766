import React from 'react';
import { Send } from 'lucide-react';

interface ChatInputProps {
  input: string;
  setInput: (value: string) => void;
  handleSendMessage: () => void;
  suggestions: string[];
  hideUpdateButton?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ 
  input, 
  setInput, 
  handleSendMessage, 
  suggestions,
  hideUpdateButton = false 
}) => {
  return (
    <div className="sticky bottom-0 border-t border-gray-700 bg-gray-900 p-4">
      <div className="max-w-3xl mx-auto">
        <div className="flex flex-wrap justify-center gap-2 mb-4">
          {suggestions.map((suggestion, index) => {
            if (hideUpdateButton && index === suggestions.length - 1) {
              return null;
            }
            return (
              <button
                key={index}
                onClick={() => setInput(suggestion)}
                className="px-4 py-2 rounded-full text-sm font-medium bg-gray-700 hover:bg-gray-600 text-gray-200 hover:text-white transition-all duration-200"
              >
                {suggestion}
              </button>
            );
          })}
        </div>
        <div className="grid grid-cols-[1fr,auto] focus-within:ring-2 focus-within:ring-blue-500 rounded-full overflow-hidden">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            placeholder="Ask about the CV..."
            className="px-4 py-3 bg-gray-800 text-gray-200 focus:outline-none"
          />
          <button
            onClick={handleSendMessage}
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 transition-colors duration-200"
          >
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;