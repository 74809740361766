import React, { useState } from 'react';
import { X, AlertTriangle } from 'lucide-react';
import { useSupabase } from '../../contexts/SupabaseContext';
import { useNavigate } from 'react-router-dom';

interface DeleteAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ isOpen, onClose }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { supabase, user } = useSupabase();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    if (!user) return;

    setIsDeleting(true);
    setError(null);

    try {
      // Delete user's CV data
      const { error: cvDeleteError } = await supabase
        .from('user_cvs')
        .delete()
        .eq('user_id', user.id);

      if (cvDeleteError) throw cvDeleteError;

      // Delete user's CV files from storage
      const { data: cvFiles } = await supabase
        .storage
        .from('cvs')
        .list(`${user.id}`);

      if (cvFiles && cvFiles.length > 0) {
        const filesToDelete = cvFiles.map(file => `${user.id}/${file.name}`);
        const { error: storageDeleteError } = await supabase
          .storage
          .from('cvs')
          .remove(filesToDelete);

        if (storageDeleteError) throw storageDeleteError;
      }

      // Delete user account
      const { error: userDeleteError } = await supabase.auth.admin.deleteUser(user.id);
      if (userDeleteError) {
        const { error: sessionError } = await supabase.auth.signOut();
        if (sessionError) throw sessionError;
      }

      navigate('/');
      onClose();
    } catch (err) {
      console.error('Error deleting account:', err);
      setError('Unable to completely delete account. Please contact support for assistance.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full m-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-red-400">Delete Account</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>

        <div className="mb-6">
          <div className="flex items-center justify-center mb-4">
            <AlertTriangle size={48} className="text-red-500" />
          </div>
          <p className="text-gray-300 text-center mb-4">
            Are you sure you want to delete your account? This action cannot be undone.
          </p>
          <div className="bg-gray-700 p-4 rounded-lg mb-4">
            <h3 className="text-red-400 font-semibold mb-2">What will be deleted:</h3>
            <ul className="text-gray-300 list-disc list-inside space-y-1">
              <li>Your account information</li>
              <li>All your uploaded CVs</li>
              <li>All chat histories</li>
              <li>All personal information</li>
            </ul>
          </div>
          <p className="text-gray-400 text-sm text-center">
            Note: For security reasons, some account data may require contacting support for complete removal.
          </p>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-500 text-white rounded-md">
            {error}
          </div>
        )}

        <div className="flex space-x-4">
          <button
            onClick={onClose}
            className="flex-1 bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-md transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteAccount}
            disabled={isDeleting}
            className="flex-1 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isDeleting ? 'Deleting...' : 'Delete Account'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;