import React from 'react';
import { Upload as UploadIcon } from 'lucide-react';

interface FileUploadProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentFile: File | null;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileChange, currentFile }) => {
  return (
    <div>
      <label htmlFor="cv-upload" className="block text-sm font-medium text-gray-300 mb-2">
        Upload New CV (PDF)
      </label>
      <div className="flex items-center justify-center w-full">
        <label htmlFor="cv-upload" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-600 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 transition-all duration-300">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <UploadIcon className="w-8 h-8 mb-3 text-gray-400" />
            <p className="mb-2 text-sm text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500">PDF (MAX. 10MB)</p>
          </div>
          <input id="cv-upload" type="file" accept=".pdf" onChange={onFileChange} className="hidden" />
        </label>
      </div>
      {currentFile && <p className="mt-2 text-sm text-gray-400">{currentFile.name}</p>}
    </div>
  );
};

export default FileUpload;